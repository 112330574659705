import React from 'react';

const HeroSection = () => {
  const isMobile = window.innerWidth <= 768; // Check if the device is mobile

  return (
    <div
      style={{
        height: '100vh',
        background: 'linear-gradient(135deg, #101010, #1a1a1a)', // Dark theme gradient
        color: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden', // To ensure smooth animations
        padding: isMobile ? '10px' : '0', // Add padding for mobile view
      }}
    >
      {/* Background Element for Animated Glow */}
      <div
        style={{
          position: 'absolute',
          top: '-50%',
          left: '50%',
          width: isMobile ? '200px' : '400px', // Adjust size for mobile
          height: isMobile ? '200px' : '400px', // Adjust size for mobile
          background: 'radial-gradient(circle, rgba(50,205,50,0.5), rgba(0,0,0,0))',
          animation: 'pulse 6s infinite',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
          pointerEvents: 'none',
        }}
      ></div>

      {/* Main Content */}
      <div
        style={{
          position: 'relative',
          zIndex: 2, // Ensures content appears above the glow
          padding: '0 20px',
        }}
      >
        <h1
          style={{
            fontSize: isMobile ? '2em' : '4em', // Smaller font size for mobile
            fontWeight: 'bold',
            fontFamily: 'Roboto, sans-serif',
            marginBottom: '20px',
            letterSpacing: '1.5px',
            textShadow: '0 4px 10px rgba(0, 230, 118, 0.8)', // Neon text glow
          }}
        >
          Welcome to PicklePlex
        </h1>
        <p
          style={{
            fontSize: isMobile ? '1em' : '1.5em', // Adjust paragraph font size
            maxWidth: isMobile ? '100%' : '800px', // Make text box width responsive
            lineHeight: isMobile ? '1.5' : '1.8',
            color: '#32CD32',
          }}
        >
          Discover world-class pickleball courts designed for all skill levels. Join us for an
          unforgettable experience of fun, fitness, and competition!
        </p>
        <button
          style={{
            marginTop: '30px',
            padding: isMobile ? '10px 20px' : '15px 30px', // Smaller button for mobile
            fontSize: isMobile ? '1em' : '1.2em',
            color: '#101010',
            background: '#00E676', // Bright neon green button
            border: 'none',
            borderRadius: '50px',
            cursor: 'pointer',
            transition: 'all 0.3s ease-in-out',
            boxShadow: '0 4px 10px rgba(0, 230, 118, 0.5)',
          }}
          onMouseEnter={(e) => {
            e.target.style.transform = 'scale(1.1)'; // Subtle zoom effect
          }}
          onMouseLeave={(e) => {
            e.target.style.transform = 'scale(1)'; // Reset zoom
          }}
        >
          Book a Court Now
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
