import React from 'react';

const PrivacyPolicy = () => (
  <div
    style={{
      padding: '50px 20px',
      backgroundColor: '#101010',
      color: '#f5f5f5',
      minHeight: '100vh',
      fontFamily: 'Roboto, sans-serif',
      lineHeight: '1.8',
    }}
  >
    <h2
      style={{
        fontSize: '2.5em',
        color: '#32CD32',
        textShadow: '0 4px 8px rgba(50, 205, 50, 0.4)',
        marginBottom: '20px',
        textAlign: 'center',
      }}
    >
      Privacy Policy
    </h2>
    <div style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'justify' }}>
      <p>
        Pickle Apex LLP is committed to protecting your privacy. This policy outlines how we collect, use, and protect
        your personal information.
      </p>

      <h3 style={{ color: '#32CD32', marginTop: '20px' }}>1. Information We Collect</h3>
      <p>
        We collect personal information such as your name, email address, phone number, and booking details when you
        use our platform. We may also collect information about your device and browsing activity to enhance your
        experience.
      </p>

      <h3 style={{ color: '#32CD32', marginTop: '20px' }}>2. How We Use Your Information</h3>
      <p>
        We use your information to:
        <ul>
          <li>Process bookings and provide our services.</li>
          <li>Communicate updates and promotional offers.</li>
          <li>Enhance user experience through analytics.</li>
        </ul>
      </p>

      <h3 style={{ color: '#32CD32', marginTop: '20px' }}>3. Data Sharing</h3>
      <p>
        We do not sell your personal information. However, we may share your data with trusted third parties for
        payment processing and service fulfillment. All third parties are bound by confidentiality agreements.
      </p>

      <h3 style={{ color: '#32CD32', marginTop: '20px' }}>4. Security Measures</h3>
      <p>
        We implement industry-standard security measures to protect your data. However, no system is completely
        secure, and we cannot guarantee absolute security.
      </p>

      <h3 style={{ color: '#32CD32', marginTop: '20px' }}>5. Your Rights</h3>
      <p>
        You can request access to or deletion of your personal information at any time by contacting us at{' '}
        <a href="mailto:pickleplexhyd@gmail.com" style={{ color: '#32CD32', textDecoration: 'none' }}>
        pickleplexhyd@gmail.com
        </a>
        .
      </p>

      <h3 style={{ color: '#32CD32', marginTop: '20px' }}>6. Policy Updates</h3>
      <p>
        This policy may be updated periodically. Continued use of our services indicates your acceptance of the
        updated policy.
      </p>
    </div>
  </div>
);

export default PrivacyPolicy;
