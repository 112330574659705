import React, { useState } from 'react';

const QuizPopup = ({ onClose }) => {
  const [step, setStep] = useState(1); // Tracks quiz steps
  const [formData, setFormData] = useState({
    playStyle: '',
    interest: '',
    playTime: '',
  });
  const [loading, setLoading] = useState(false); // Loading indicator

  const handleNext = () => setStep((prev) => prev + 1);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true); // Show loading indicator
    try {
      const response = await fetch('http://localhost:5001/api/quiz-responses', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to save quiz responses');
      }

      const result = await response.json();
      console.log('Response saved:', result);

      // Success message with discount code
      alert(`🎉 Thank you for your answers! You've unlocked a 10% discount code: PICKLE10`);
    } catch (error) {
      console.error('Error saving responses:', error);
      alert('❌ There was an error submitting your responses. Please try again.');
    } finally {
      setLoading(false); // Hide loading indicator
      onClose(); // Close the popup
    }
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.85)', // Overlay
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          background: '#101010',
          padding: '30px',
          borderRadius: '10px',
          textAlign: 'center',
          color: '#f5f5f5',
          maxWidth: '400px',
          width: '90%',
          boxShadow: '0 4px 15px rgba(0, 0, 0, 0.5)',
          fontFamily: `'Poppins', sans-serif`,
        }}
      >
        <h2
          style={{
            color: '#32CD32', // Neon green for heading
            marginBottom: '20px',
            textShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
            fontSize: '1.8em',
          }}
        >
          🎉 Unlock Your 10% Discount!
        </h2>

        {step === 1 && (
          <>
            <p style={{ marginBottom: '20px', fontWeight: 'bold' }}>
              🏓 What's your favorite pickleball game style?
            </p>
            <select
              name="playStyle"
              onChange={handleInputChange}
              value={formData.playStyle}
              style={selectStyle}
            >
              <option value="">Select</option>
              <option value="Singles">Singles</option>
              <option value="Doubles">Doubles</option>
              <option value="Casual">Casual</option>
              <option value="Competitive">Competitive</option>
            </select>
            <button onClick={handleNext} style={buttonStyle}>
              Next
            </button>
          </>
        )}

        {step === 2 && (
          <>
            <p style={{ marginBottom: '20px', fontWeight: 'bold' }}>
              💡 What interests you most about pickleball?
            </p>
            <select
              name="interest"
              onChange={handleInputChange}
              value={formData.interest}
              style={selectStyle}
            >
              <option value="">Select</option>
              <option value="Coaching">Coaching</option>
              <option value="Tournaments">Tournaments</option>
              <option value="Socializing">Socializing</option>
              <option value="Fitness">Fitness</option>
            </select>
            <button onClick={handleNext} style={buttonStyle}>
              Next
            </button>
          </>
        )}

        {step === 3 && (
          <>
            <p style={{ marginBottom: '20px', fontWeight: 'bold' }}>
              ⏰ When do you prefer to play?
            </p>
            <select
              name="playTime"
              onChange={handleInputChange}
              value={formData.playTime}
              style={selectStyle}
            >
              <option value="">Select</option>
              <option value="Morning">Morning</option>
              <option value="Afternoon">Afternoon</option>
              <option value="Evening">Evening</option>
            </select>
            <button onClick={handleSubmit} style={buttonStyle}>
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </>
        )}

        <button
          onClick={onClose}
          style={{
            ...buttonStyle,
            background: 'transparent',
            color: '#32CD32',
            marginTop: '20px',
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

// Shared styles
const selectStyle = {
  width: '100%',
  padding: '10px',
  marginBottom: '20px',
  borderRadius: '5px',
  border: 'none',
  background: '#1a1a1a',
  color: '#32CD32', // Neon green for text
  fontSize: '1em',
  textAlign: 'center',
};

const buttonStyle = {
  width: '100%',
  padding: '10px',
  fontSize: '1em',
  background: '#32CD32', // Neon green
  color: '#101010',
  border: 'none',
  borderRadius: '50px',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  boxShadow: '0 4px 8px rgba(50, 205, 50, 0.4)',
  fontWeight: 'bold',
};

export default QuizPopup;
