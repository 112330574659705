import React from 'react';

const StructuredData = () => (
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "PicklePlex",
      "image": "/android-chrome-192x192.png",
      "address": {
        "@type": "Address",
        "streetAddress": "Meridian School Rd, Madhapur",
        "addressLocality": "Hyderabad",
        "postalCode": "500081",
        "addressCountry": "IN",
      },
      "telephone": "+91-9063068086",
      "url": "https://www.pickleplex.pro/",
    })}
  </script>
);

export default StructuredData;
