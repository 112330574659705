import React from 'react';

const TermsOfUse = () => (
  <div
    style={{
      padding: '50px 20px',
      backgroundColor: '#101010',
      color: '#f5f5f5',
      minHeight: '100vh',
      fontFamily: 'Roboto, sans-serif',
      lineHeight: '1.8',
    }}
  >
    <h2
      style={{
        fontSize: '2.5em',
        color: '#32CD32',
        textShadow: '0 4px 8px rgba(50, 205, 50, 0.4)',
        marginBottom: '20px',
        textAlign: 'center',
      }}
    >
      Terms of Use
    </h2>
    <div style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'justify' }}>
      <p>
        Welcome to Pickle Apex LLP! By accessing or using our services, you agree to be bound by these Terms of Use.
        If you do not agree with any part of these terms, please refrain from using our services.
      </p>

      <h3 style={{ color: '#32CD32', marginTop: '20px' }}>1. Acceptance of Terms</h3>
      <p>
        By using our platform, you confirm that you are at least 18 years old and have the capacity to enter into a
        binding contract. If you are under 18, you may use our services only under the supervision of a parent or
        legal guardian.
      </p>

      <h3 style={{ color: '#32CD32', marginTop: '20px' }}>2. User Responsibilities</h3>
      <p>
        You agree to use our platform only for lawful purposes. You must not:
        <ul>
          <li>Engage in any activity that disrupts or interferes with our services.</li>
          <li>Upload harmful or inappropriate content.</li>
          <li>Misrepresent your identity or impersonate others.</li>
        </ul>
      </p>

      <h3 style={{ color: '#32CD32', marginTop: '20px' }}>3. Intellectual Property</h3>
      <p>
        All content on our platform, including text, graphics, logos, and software, is the property of Pickle Apex LLP
        and protected by applicable intellectual property laws. You may not reproduce, distribute, or modify any
        content without our prior written consent.
      </p>

      <h3 style={{ color: '#32CD32', marginTop: '20px' }}>4. Limitation of Liability</h3>
      <p>
        Pickle Apex LLP will not be held liable for any damages arising from the use of our platform, including direct,
        indirect, incidental, or consequential damages.
      </p>

      <h3 style={{ color: '#32CD32', marginTop: '20px' }}>5. Amendments</h3>
      <p>
        We reserve the right to modify these terms at any time. Please review them periodically to stay informed.
        Continued use of our services constitutes acceptance of the updated terms.
      </p>

      <p style={{ marginTop: '20px' }}>
        For any questions or concerns regarding these Terms of Use, contact us at{' '}
        <a href="mailto:pickleplexhyd@gmail.com" style={{ color: '#32CD32', textDecoration: 'none' }}>
        pickleplexhyd@gmail.com
        </a>
        .
      </p>
    </div>
  </div>
);

export default TermsOfUse;
