import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    query: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, query } = formData;
    window.location.href = `mailto:pickleplexhyd@gmail.com?subject=Query from ${name}&body=${encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\n\nQuery/Issue:\n${query}`
    )}`;
  };

  return (
    <div
      style={{
        padding: '50px 20px',
        background: 'linear-gradient(180deg, #101010, #1a1a1a)', // Dark theme gradient
        textAlign: 'center',
        color: '#f5f5f5',
      }}
    >
      <h2
        style={{
          fontSize: '2.5em',
          color: '#32CD32', // Neon yellow for heading
          textShadow: '0 4px 8px rgba(50, 205, 50, 0.2)', // Glow effect
          marginBottom: '30px',
          fontFamily: 'Roboto, sans-serif',
        }}
      >
        Contact Us
      </h2>
      <p
        style={{
          fontSize: '1.2em',
          lineHeight: '1.8',
          margin: '0 auto 20px',
          maxWidth: '800px',
          color: '#f5f5f5',
        }}
      >
        We’d love to hear from you! Reach out with your queries or feedback.
      </p>
      <div
        style={{
          marginTop: '30px',
          marginBottom: '50px',
          fontSize: '1.2em',
          lineHeight: '1.6',
          color: '#f5f5f5',
        }}
      >
        <p><strong>Email:</strong> pickleplexhyd@gmail.com</p>
        <p><strong>Phone:</strong> +91-9063068086</p>
        <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
          {/* <strong>Address:</strong> */}
          <img
              src='/gmapslogo.png'
              alt="Google Maps"
              style={{ width: '40px', height: '38px', cursor: 'pointer' }}
            />
          <a
            href="https://maps.app.goo.gl/sY7coi3TfSqvQVQr7?g_st=iw"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: '#32CD32', fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: '10px' }}
          >
            F94P+C7X, Meridian School Rd, Madhapur, Hyderabad
            
          </a>
        </p>
      </div>

      {/* Contact Form */}
      <form
        onSubmit={handleSubmit}
        style={{
          maxWidth: '500px',
          margin: '0 auto',
          textAlign: 'left',
          padding: '20px',
          background: 'rgba(255, 255, 255, 0.1)', // Translucent background
          borderRadius: '10px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)',
        }}
      >
        <label
          htmlFor="name"
          style={{
            fontSize: '1em',
            color: '#f5f5f5',
            display: 'block',
            marginBottom: '10px',
          }}
        >
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Your Name"
          required
          style={{
            width: '90%',
            padding: '10px',
            fontSize: '1em',
            marginBottom: '20px',
            border: '1px solid rgba(255, 255, 255, 0.2)',
            borderRadius: '5px',
            background: '#1a1a1a',
            color: '#f5f5f5',
          }}
        />

        <label
          htmlFor="email"
          style={{
            fontSize: '1em',
            color: '#f5f5f5',
            display: 'block',
            marginBottom: '10px',
          }}
        >
          Email
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Your Email"
          required
          style={{
            width: '90%',
            padding: '10px',
            fontSize: '1em',
            marginBottom: '20px',
            border: '1px solid rgba(255, 255, 255, 0.2)',
            borderRadius: '5px',
            background: '#1a1a1a',
            color: '#f5f5f5',
          }}
        />

        <label
          htmlFor="query"
          style={{
            fontSize: '1em',
            color: '#f5f5f5',
            display: 'block',
            marginBottom: '10px',
          }}
        >
          Query/Issue
        </label>
        <textarea
          id="query"
          name="query"
          rows="5"
          value={formData.query}
          onChange={handleChange}
          placeholder="Describe your query or issue"
          required
          style={{
            width: '90%',
            padding: '10px',
            fontSize: '1em',
            marginBottom: '20px',
            border: '1px solid rgba(255, 255, 255, 0.2)',
            borderRadius: '5px',
            background: '#1a1a1a',
            color: '#f5f5f5',
          }}
        ></textarea>

        <button
          type="submit"
          style={{
            width: '90%',
            padding: '15px',
            fontSize: '1.2em',
            color: '#101010',
            background: '#32CD32', // Neon yellow button
            border: 'none',
            borderRadius: '50px',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            boxShadow: '0 4px 8px rgba(50, 205, 50, 0.8)',
          }}
          onMouseEnter={(e) => {
            e.target.style.transform = 'scale(1.05)';
            e.target.style.boxShadow = '0 6px 15px rgba(255, 223, 0, 0.8)';
          }}
          onMouseLeave={(e) => {
            e.target.style.transform = 'scale(1)';
            e.target.style.boxShadow = '0 4px 8px rgba(50, 205, 50, 0.8)';
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Contact;
