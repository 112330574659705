import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* Logo on the left */}
        <div className="navbar-logo-container">
          <img src="/onblack_logo-modified.png" alt="PicklePlex Logo" className="navbar-logo-image" />
          <Link to="/" className="navbar-logo-text">
            PicklePlex
          </Link>
        </div>
       

        {/* Hamburger Menu Icon */}
        <div className="hamburger-icon" onClick={toggleMobileMenu}>
          <span />
          <span />
          <span />
        </div>

        {/* Navigation Links */}
        <ul className={`navbar-links ${isMobileMenuOpen ? 'open' : ''}`}>
          <li>
            <Link to="/" className="navbar-link" onClick={() => setIsMobileMenuOpen(false)}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="navbar-link" onClick={() => setIsMobileMenuOpen(false)}>
              About
            </Link>
          </li>
          <li>
            <Link to="/contact" className="navbar-link" onClick={() => setIsMobileMenuOpen(false)}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
