import React, { useEffect, useState } from 'react';
import HeroSection from '../sections/HeroSection';
import InteractiveScroll from '../sections/InteractiveScroll';
import QuizPopup from '../components/QuizPopup';
import SeoTags from '../SeoTags';
import StructuredData from '../StructuredData';


const Home = () => {
  const [showQuizPopup, setShowQuizPopup] = useState(false);

  useEffect(() => {
    // Check if the quiz popup has already been shown
    const hasQuizPopupShown = localStorage.getItem('quizPopupShown');
    if (!hasQuizPopupShown) {
      setShowQuizPopup(true); // Show the popup
      localStorage.setItem('quizPopupShown', 'true'); // Mark as shown
    }
  }, []);

  const handleQuizClose = () => {
    setShowQuizPopup(false); // Close the popup
  };

  return (
    <div>
      <HeroSection />
      <InteractiveScroll />
      <SeoTags
      title="PicklePlex - Premier Pickleball Courts in Hyderabad"
      description="Discover world-class pickleball courts at PicklePlex. Join us for fun, fitness, and community in Hyderabad!"
      keywords="Pickleball Hyderabad, PicklePlex, Pickleball Courts"
    />
    <StructuredData />
      {showQuizPopup && <QuizPopup onClose={handleQuizClose} />}
    </div>
  );
};

export default Home;
