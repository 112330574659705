import React, { useEffect } from 'react';
import { gsap } from 'gsap';

const About = () => {
  useEffect(() => {
    // Title animation
    gsap.fromTo(
      '.about-title',
      { opacity: 0, scale: 0.5, y: -50 },
      { opacity: 1, scale: 1, y: 0, duration: 1.5, ease: 'elastic.out(1, 0.75)' }
    );

    // Content animation
    gsap.fromTo(
      '.about-content',
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1.5, stagger: 0.3, ease: 'power3.out' }
    );

    // Rotating paddles
    gsap.to('.paddle-image', {
      rotationY: 360,
      duration: 5,
      repeat: -1,
      ease: 'linear',
    });
  }, []);

  return (
    <div className="about-container">
      {/* Title */}
      <h2 className="about-title">Welcome To Pickleplex</h2>

      {/* Content Section */}
      <div className="about-content-container">
        {/* Left Paddle Image */}
        <img className="paddle-image" src="/aboutimage.png" alt="Rotating Paddle" />

        {/* Text Section */}
        <div className="about-text">
          <p className="about-content">
            Hyderabad’s premier pickleball destination where fun meets fitness and community!
          </p>
          <p className="about-content">
          Nestled in the vibrant heart of Madhapur, we’re not just a sports facility—we’re a lifestyle experience designed for players of all ages and skill levels.
          </p>
          <p className="about-content">
            Whether you're a seasoned pro or a curious beginner, Pickleplex is where your game begins.
          </p>
          <p className="about-content">
            With top-notch courts, a lively atmosphere, and a passionate community, we redefine how Hyderabad unwinds.
          </p>
          <p className="about-content">
            From friendly matches to competitive leagues, we’ve got something exciting for everyone. Come for the game, stay for the energy, and leave as part of the Pickleplex family.
          </p>
          <p className="about-content">
          Ready to serve up some fun? Let’s play! 🌕✨
          </p>
        </div>

        {/* Right Paddle Image */}
        <img className="paddle-image" src="/aboutimage.png" alt="Rotating Paddle" />
      </div>
    </div>
  );
};

export default About;
