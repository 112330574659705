import React from 'react';

const CancellationRefundPolicy = () => (
  <div
    style={{
      padding: '50px 20px',
      background: 'linear-gradient(180deg, #101010, #1a1a1a)', // Dark gradient background
      color: '#f5f5f5',
      minHeight: '100vh',
      fontFamily: 'Roboto, sans-serif',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <h2
      style={{
        fontSize: '2.5em',
        color: '#32CD32', // Neon green for headings
        textShadow: '0 4px 8px rgba(50, 205, 50, 0.5)',
        marginBottom: '30px',
        textAlign: 'center',
      }}
    >
      Cancellation & Refund Policy
    </h2>

    <div
      style={{
        maxWidth: '800px',
        background: 'rgba(0, 0, 0, 0.9)', // Translucent black box
        borderRadius: '10px',
        padding: '30px',
        boxShadow: '0 4px 10px rgba(0, 255, 0, 0.2)', // Neon green shadow
        textAlign: 'justify',
        lineHeight: '1.8',
      }}
    >
      <h3
        style={{
          color: '#32CD32',
          fontSize: '1.5em',
          marginBottom: '15px',
          textAlign: 'left',
        }}
      >
        Cancellation Policy
      </h3>
      <p style={{ fontSize: '1.1em', marginBottom: '20px' }}>
        If cancellation is within 12 hours of the slot start time, no refund will be made. If
        cancellation is more than 12 hours prior, a <strong>95% amount paid</strong> will be
        refunded in the form of credits.
      </p>

      <h3
        style={{
          color: '#32CD32',
          fontSize: '1.5em',
          marginBottom: '15px',
          textAlign: 'left',
        }}
      >
        Rain Policy
      </h3>
      <p style={{ fontSize: '1.1em' }}>
        In case the court is not usable due to rain, the guest may email respective center emails
        with a snapshot of the booking and request a refund. The request needs to be made within
        <strong> 12 hours</strong> from the slot time. Center management will verify and, at its
        discretion, give credits equivalent to <strong>95% of the payment amount</strong>.
      </p>
    </div>
  </div>
);

export default CancellationRefundPolicy;
