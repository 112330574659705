import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const Coachings = () => {
  const headingRef = useRef(null);
  const loadingBarRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    // GSAP Animations for text and loading bar
    gsap.fromTo(
      headingRef.current,
      { opacity: 0, y: -50 },
      { opacity: 1, y: 0, duration: 1, ease: 'power2.out' }
    );

    gsap.fromTo(
      loadingBarRef.current,
      { opacity: 0, scaleX: 0 },
      { opacity: 1, scaleX: 1, duration: 1, ease: 'power2.out', delay: 0.5 }
    );

    gsap.fromTo(
      descriptionRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: 'power2.out', delay: 1 }
    );
  }, []);

  return (
    <div
      style={{
        padding: '50px 20px',
        textAlign: 'center',
        background: 'linear-gradient(180deg, #101010, #1a1a1a)',
        color: '#f5f5f5',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', // Center vertically
        alignItems: 'center', // Center horizontally
      }}
    >
      {/* "Coming Soon" Text */}
      <h1
        ref={headingRef}
        style={{
          fontSize: '4em',
          color: '#32CD32', // Neon green text
          textShadow: '0 4px 8px rgba(50, 205, 50, 0.8)', // Glow effect
          marginBottom: '30px',
        }}
      >
        Coming Soon
      </h1>

      {/* Cool Loading Bar */}
      <div
        ref={loadingBarRef}
        style={{
          width: '80%',
          maxWidth: '600px',
          height: '20px',
          background: '#2a2a2a', // Dark background for the loading bar
          borderRadius: '10px',
          overflow: 'hidden',
          position: 'relative',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            width: '50%',
            height: '100%',
            background: 'linear-gradient(90deg, #32CD32, #FFD700)',
            position: 'absolute',
            animation: 'loadingBar 2s infinite',
          }}
        ></div>
      </div>

      {/* Bottom Description */}
      <p
        ref={descriptionRef}
        style={{
          fontSize: '1.5em',
          lineHeight: '1.8',
          color: '#f5f5f5',
          maxWidth: '800px',
          marginTop: '20px',
        }}
      >
        Exciting Coaching Sessions Coming Soon!
        PicklePlex is preparing to launch tailored coaching programs designed to elevate your pickleball game. Whether you're a beginner eager to learn the basics or an advanced player refining your skills, our expert-led sessions will have something for everyone.
        Stay tuned as we craft engaging, hands-on training experiences to help you master techniques, boost your confidence, and dominate the court. The journey to becoming the best pickleball player starts here—get ready to learn, play, and excel with PicklePlex!
      </p>

      {/* CSS for Loading Animation */}
      <style>
        {`
          @keyframes loadingBar {
            0% {
              left: -50%;
            }
            50% {
              left: 25%;
            }
            100% {
              left: 100%;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Coachings;
